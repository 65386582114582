<template>
  <div class="pickingUpDiv">Picking Up</div>
</template>

<script>
export default {
  name: "PickingUp",
};
</script>

<style scoped>
.pickingUpDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 30px;
  background: #e8e9eb;
  color: #2c3e50;
  border-radius: 8px;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
</style>
