<template>
  <div class="col-lg-12 mb-3">
    <h5 class="dByTitle">{{ dashboardLabel.deliver_By }}</h5>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-12 d-flex" id="driverDiv">
    <div class="col-lg-2 d-flex justify-content-center align-items-center">
      <SmallAvatar />
    </div>
    <div class="ms-2 col-lg-10 d-flex align-items-center mx-0">
      <div>
        <p class="mb-0">{{ dashboardLabel.deliver_By }}</p>
        <p class="mb-0">
          {{
            savedData.driverDetails !== null ? driverName : "Not assigned yet"
          }}
        </p>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-12 d-flex" id="driverDiv">
    <div class="col-lg-2 d-flex justify-content-center align-items-center">
      <div class="smallAvatarCircle">
        <img :src="phoneImage" alt="" class="smallAvatarImage" />
      </div>
    </div>
    <div class="ms-2 col-lg-10 d-flex align-items-center mx-0">
      <div>
        <p class="mb-0">{{ dashboardLabel.deliver_Number }}</p>
        <p class="mb-0">
          {{
            savedData.driverDetails !== null
              ? contactNumber
              : "Not assigned yet"
          }}
        </p>
      </div>
    </div>
  </div>

  <div class="col-lg-4 col-md-4 col-sm-12 d-flex" id="deliverDiv">
    <div class="col-lg-2 d-flex justify-content-center align-items-center">
      <div class="smallAvatarCircle">
        <img :src="deliverImage" alt="" class="smallAvatarImage iconTime" />
      </div>
    </div>
    <div class="ms-2 col-lg-10">
      <div class="mt-1">
        <p class="mb-0">{{ dashboardLabel.deliver_Time }}</p>
        <p class="mb-0">
          {{
            savedData.driverDetails !== null
              ? deliveredDateTime
              : "Not assigned yet"
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import SmallAvatar from "./SmallAvatar";
import phoneImage from "../../../assets/img/phone.png";
import deliverImage from "../../../assets/img/deliver.png";
import BookingService from "../../../services/BookingService";
import InvoiceBookingDetailsService from "../../../services/InvoiceBookingDetailsService";
import moment from "moment";

export default {
  name: "DeliverBy",
  components: { SmallAvatar },
  data: function () {
    return {
      dashboardLabel: this.$store.getters.dashboardDetails,
      phoneImage,
      deliverImage,
      dropOffDateTime: "",
      isLanguageEN: true,
      savedData: {},
      driverName: "Not assigned yet",
      contactNumber: "Not assigned yet",
      deliveredDateTime: "Not completed yet",
    };
  },
  mounted() {
    const langType = localStorage.getItem("lang");
    if (langType === "EN") {
      this.isLanguageEN = true;
    } else if (langType === "AR") {
      this.isLanguageEN = false;
    }

    InvoiceBookingDetailsService.getInvoiceBookingDetails(
      this.$route.params.bookinID
    )
      .then((response) => {
        if (response.data.bookingId != 0) {
          this.savedData = response.data;
          this.driverName = response.data.driverDetails
            ? response.data.driverDetails.driverName
            : "Not assigned yet";
          this.contactNumber = response.data.driverDetails
            ? response.data.driverDetails.contactNumber
            : "Not assigned yet";
          this.deliveredDateTime = response.data.driverDetails
            ? response.data.driverDetails.deliveredDateTime
              ? moment(response.data.driverDetails.deliveredDateTime).format(
                  "LLLL"
                )
              : "Not completed yet"
            : "Not completed yet";

          console.log(this.driverName);
        } else {
          window.location.href = "/";
        }
      })
      .catch((error) => {});
    // BookingService.getBookingDetails(this.$route.params.bookinID)
    //   .then((response) => {
    //     this.dropOffDateTime = moment(response.data.dropOffDateTime).format(
    //       "LT"
    //     );
    //   })
    //   .catch((error) => {
    //     // console.log(error);
    //   });
  },
};
</script>

<style scoped>
.dByTitle {
  color: #006203;
  margin-top: 15px;
}
.smallAvatarCircle {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: #d1eed2;
}

.smallAvatarImage {
  border-radius: 100%;
  background-size: contain;
  margin: 15px;
}
.iconTime {
  width: 28px;
  margin-left: 12px;
}

@media all and (max-width: 765px) {
  #phoneDiv {
    margin-top: 25px;
  }

  #deliverDiv {
    margin-top: 25px;
  }
}
</style>
