<template>
  <div class="card col-12 col-lg-4 col-sm-12 statusDiv">
    <div class="row container-fluid me-0 mx-0 pe-0 px-0">
      <div
        class="
          col-12
          d-flex
          flex-lg-row flex-md-row flex-column
          align-items-lg-center align-items-md-center
        "
      >
        <p class="mx-lg-2 me-lg-3 me-md-2 mt-3">
          {{ !isLanguageEN ? "#" : "" }}{{ dashboardLabel.headerTitle }}
          {{ isLanguageEN ? "#" : "" }}{{ this.savedData.bookingId }}
        </p>
        <div
          :class="
            this.getLabel(this.savedData.currentStatus) === 'Cancelled'
              ? 'canceledDiv'
              : this.getLabel(this.savedData.currentStatus) === 'Pending'
              ? 'pickingUpDiv'
              : this.getLabel(this.savedData.currentStatus) === 'Inprogress'
              ? 'pickingUpDiv'
              : this.getLabel(this.savedData.currentStatus) === 'Completed'
              ? 'pickingUpDiv-Completed'
              : this.getLabel(this.savedData.currentStatus) === 'Stored'
              ? 'pickingUpDiv'
              : this.getLabel(this.savedData.currentStatus) === 'Pickup'
              ? 'pickingUpDiv'
              : this.getLabel(this.savedData.currentStatus) === 'On-Delivery'
              ? 'pickingUpDiv'
              : 'pickingUpDiv'
          "
        >
          {{ this.getLabel(this.savedData.currentStatus) }}
        </div>
        <!-- <div class="pickingUpDiv" v-show="this.isActive">
          {{ dashboardLabel.pickUp }}
        </div>
        <div class="canceledDiv" v-show="!this.isActive">
          {{ dashboardLabel.canceled }}
        </div> -->
      </div>
    </div>
    <div
      class="col-10 mx-lg-4 mt-0 mx-md-3 me-lg-1 d-flex justify-content-start"
    >
      <div class="mt-2" id="QRDIV" v-show="isQrActive">
        <qrcode-vue :value="value" :size="size" level="H" />
      </div>
      <div class="mt-2" v-show="!isQrActive">
        <label class="danger-card"><i>No driver assigned yet. </i></label>
      </div>
    </div>
    <div class="col-10 mx-lg-4 mt-0 mx-md-3 me-lg-1 statusBody">
      <div class="row pb-5 mt-3">
        <div class="col-2 text-center" id="statusBar">
          <div
            :class="BookingConfirm ? 'col-1 dCircle' : 'col-1 dCircleInActive'"
          ></div>
          <div
            :class="BookingConfirm ? 'col-1 dSqu' : 'col-1 dSquInActive'"
          ></div>
          <div
            :class="BookingConfirm ? 'col-1 dCircle' : 'col-1 dCircleInActive'"
          ></div>
          <div
            :class="BookingConfirm ? 'col-1 dSqu' : 'col-1 dSquInActive'"
          ></div>
          <div
            :class="
              PickingUpConfirm ? 'col-1 dCircle' : 'col-1 dCircleInActive'
            "
          ></div>
          <div
            :class="PickingUpConfirm ? 'col-1 dSqu' : 'col-1 dSquInActive'"
          ></div>
          <div
            :class="onStored ? 'col-1 dCircle' : 'col-1 dCircleInActive'"
          ></div>
          <div :class="onStored ? 'col-1 dSqu' : 'col-1 dSquInActive'"></div>
          <div
            :class="onDelivery ? 'col-1 dCircle' : 'col-1 dCircleInActive'"
          ></div>
          <div :class="onDelivery ? 'col-1 dSqu' : 'col-1 dSquInActive'"></div>
          <div
            :class="luggageDelivery ? 'col-1 dCircle' : 'col-1 dCircleInActive'"
          ></div>
        </div>
        <div class="col-10">
          <div class="status-data-1">
            <h5 class="m-0">{{ dashboardLabel.booking_Confirmed }}</h5>
            <label class="m-0"></label>
          </div>
          <div class="status-data">
            <h5 class="m-0">{{ dashboardLabel.payment_Success }}</h5>
            <label class="m-0"></label>
          </div>
          <div class="status-data">
            <h5 class="m-0">{{ dashboardLabel.pickUp }}</h5>
            <label class="m-0"></label>
          </div>
          <div class="status-data">
            <h5 class="m-0">{{ dashboardLabel.on_Store }}</h5>
            <label class="m-0"></label>
          </div>
          <div class="status-data">
            <h5 class="m-0">{{ dashboardLabel.on_Delivery }}</h5>
            <label class="m-0"></label>
          </div>
          <div class="status-data">
            <h5 class="m-0">{{ dashboardLabel.luggages_delivered }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PickingUp from "./PickingUp";
import BookingService from "../../../services/BookingService";
import QrcodeVue from "qrcode.vue";
import InvoiceBookingDetailsService from "../../../services/InvoiceBookingDetailsService";

export default {
  name: "BookingStatus",
  components: { PickingUp, QrcodeVue },
  data() {
    return {
      dashboardLabel: this.$store.getters.dashboardDetails,
      BookingConfirm: true,
      PickingUpConfirm: false,
      onDelivery: false,
      onStored: false,
      luggageDelivery: false,
      id: 0,
      savedData: {},
      isQrActive: false,
      value: "",
      size: 100,
      isLanguageEN: true,
      isActive: true,
    };
  },
  mounted() {
    const langType = localStorage.getItem("lang");
    if (langType === "EN") {
      this.isLanguageEN = true;
    } else if (langType === "AR") {
      this.isLanguageEN = false;
    }
    InvoiceBookingDetailsService.getInvoiceBookingDetails(
      this.$route.params.bookinID
    )
      .then((response) => {
        // this.id = response.data.passangerBookingID;
        this.savedData = response.data;
        this.BookingConfirm = this.savedData.status.confirm ? true : false;
        this.PickingUpConfirm = this.savedData.status.pickup ? true : false;
        this.onDelivery = this.savedData.status.onDelivery ? true : false;
        this.onStored = this.savedData.status.stored ? true : false;
        this.luggageDelivery = this.savedData.status.stored ? true : false;
        if (this.savedData.currentStatus === 5) {
          this.BookingConfirm = true;
          this.PickingUpConfirm = true;
          this.onStored = true;
          this.onDelivery = false;
          this.luggageDelivery = false;
        }
        if (this.savedData.currentStatus === 3) {
          this.BookingConfirm = true;
          this.PickingUpConfirm = true;
          this.onStored = true;
          this.onDelivery = true;
          this.luggageDelivery = true;
        }
        this.isQrActive = this.savedData.qrCode ? true : false;
        this.value = this.savedData.qrCode;
      })
      .catch((error) => {
        // console.log(error);
      });

    // BookingService.getQrcode(this.$route.params.bookinID, "CustomerToDriver")
    //   .then((response) => {
    //     this.isQrActive = true;
    //     // this.id = response.data.passangerBookingID;
    //     this.value = response.data;
    //   })
    //   .catch((error) => {
    //     this.isQrActive = false;
    //   });
    // console.log("QR", this.isQrActive);
  },
  methods: {
    getLabel(item) {
      let label = "";
      if (item !== null && item !== undefined) {
        switch (item) {
          case 0:
            label = "Pending";
            break;
          case 1:
            label = "Pending";
            break;
          case 2:
            label = "Inprogress";
            break;
          case 3:
            label = "Completed";
            break;
          case 4:
            label = "Cancelled";
            break;
          case 5:
            label = "Stored";
            break;
          case 6:
            label = "Confirmed";
            break;
          case 7:
            label = "Pickup";
            break;
          case 8:
            label = "On-Delivery";
        }
      }

      return label;
    },
  },
};
</script>

<style scoped>
.dSqu {
  position: relative;
  left: 10px;
  width: 2px;
  height: 50px;
  background: #e8e9eb;
}

.dSquInActive {
  position: relative;
  left: 10px;
  width: 2px;
  height: 50px;
  background: #e8e9eb;
}

.dCircleInActive {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #e8e9eb;
}

.dCircle {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #267929;
}

.statusDiv {
  width: 100%;
  border-radius: 13px;
  height: 100%;
}

.statusBody {
  height: auto;
  margin: auto;
}
.status-data {
  margin-top: 21px;
}
.pickingUpDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 30px;
  background: #e8e9eb;
  color: #2c3e50;
  border-radius: 8px;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.pickingUpDiv-Completed {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 30px;
  background: #006203;
  color: #ffffff;
  border-radius: 8px;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.canceledDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 30px;
  background: #dd3030;
  color: #ffffff;
  border-radius: 8px;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.danger-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 30px;
  padding: 15px;

  /* background: #db0e03; */
  color: #000000;
  border-radius: 8px;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
@media screen and (max-width: 1366px) {
  .dSqu {
    position: relative;
    width: 2px;
    height: 50px;
    background: #d1eed2;
  }

  .dSquInActive {
    position: relative;
    width: 2px;
    height: 50px;
    background: #e8e9eb;
  }

  .dCircleInActive {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #e8e9eb;
  }
}

@media screen and (max-width: 1440px) {
  .dSqu {
    position: relative;
    width: 2px;
    height: 50px;
    background: #d1eed2;
  }

  .dSquInActive {
    position: relative;
    width: 2px;
    height: 50px;
    background: #e8e9eb;
  }

  .dCircleInActive {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #e8e9eb;
  }
}

@media screen and (max-width: 1024px) {
  .dSqu {
    position: relative;
    width: 2px;
    height: 50px;
    background: #d1eed2;
  }

  .dSquInActive {
    position: relative;
    width: 2px;
    height: 50px;
    background: #e8e9eb;
  }

  .dCircleInActive {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #e8e9eb;
  }
}

@media screen and (max-width: 768px) {
  .dSqu {
    position: relative;
    width: 2px;
    height: 50px;
    background: #d1eed2;
  }

  .dSquInActive {
    position: relative;
    width: 2px;
    height: 50px;
    background: #e8e9eb;
  }

  .dCircleInActive {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #e8e9eb;
  }
}
@media (min-width: 200px) and (max-width: 363px) {
  .dSqu {
    position: relative;
    width: 2px;
    height: 50px;
    background: #d1eed2;
  }

  .dSquInActive {
    position: relative;
    width: 2px;
    height: 50px;
    background: #e8e9eb;
  }

  .dCircleInActive {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #e8e9eb;
  }
  .status-data {
    margin-top: 30px !important;
  }
  .status-data-1 h5,
  label {
    font-size: 13px !important;
  }
  .status-data h5,
  label {
    font-size: 13px !important;
  }
}
@media (min-width: 363px) and (max-width: 425px) {
  .dSqu {
    position: relative;
    width: 2px;
    height: 50px;
    background: #d1eed2;
  }

  .dSquInActive {
    position: relative;
    width: 2px;
    height: 50px;
    background: #e8e9eb;
  }

  .dCircleInActive {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #e8e9eb;
  }
  .status-data {
    margin-top: 27px !important;
  }
  .status-data-1 h5,
  label {
    font-size: 16px !important;
  }
  .status-data h5,
  label {
    font-size: 16px !important;
  }

  /* #statusDiv {
    height: 600px;
    margin-bottom: 30px;
    padding-bottom: 100px;
  }

  #QRDIV {
    position: absolute;
    bottom: -100px;
    left: 73px;
  }

  #statusBar {
    height: 435px;
  } */
}

@media screen and (max-width: 1024px) {
}
</style>
