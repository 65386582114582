<template>
  <div
    id="luggageMainDiv"
    class="
      row
      d-flex
      justify-content-center justify-content-md-start
      pb-1
      ms-md-1
      col-md-12 col-12
      w-100
    "
  >
    <div
      class="
        ms-4 ms-sm-4 ms-md-0
        mb-md-2 mb-sm-2
        container-fluid-lg
        luggagetDiv
      "
    >
      <div class="col-12 col-sm-12">
        <div>
          <div class="d-flex justify-content-start align-item-center">
            <div class="col-lg-12 col-sm-12" :dir="!isLanguageEN ? 'rtl' : ''">
              <h4 class="text-start ps-3 ps-md-2 mt-4 m-b-4 captionText">
                {{ dashboardLabel.luggages }}
              </h4>
            </div>
          </div>
          <div class="mt-2">
            <div v-if="myModel">
              <transition name="model">
                <div class="modal-mask">
                  <div class="modal-wrapper">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button
                            type="button"
                            class="close btn"
                            @click="closeModel"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div class="form-group">
                            <img
                              v-if="uploadedLuggageImage != ''"
                              id="luggageLargePreview"
                              :src="uploadedLuggageImage"
                              alt=""
                            />
                            <label
                              class="d-flex justify-content-center"
                              v-if="uploadedLuggageImage == ''"
                            >
                              No image available.
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="d-flex justify-content-center align-item-center mt-3">
            <div class="col-lg-12 col-sm-12">
              <table
                class="table table-borderless"
                :dir="!isLanguageEN ? 'rtl' : ''"
              >
                <thead>
                  <tr>
                    <th class="opacity-50" scope="col">
                      {{ dashboardLabel.luggages_Type }}
                    </th>
                    <th class="opacity-50" scope="col">
                      {{ dashboardLabel.luggages_Number }}
                    </th>
                    <th class="opacity-50" scope="col">
                      {{ dashboardLabel.handling_Instructions }}
                    </th>
                    <th class="opacity-50" scope="col">
                      {{ dashboardLabel.luggages_Weight }}
                    </th>
                    <th class="opacity-50" scope="col">
                      {{ dashboardLabel.luggages_Photo }}
                    </th>
                  </tr>
                </thead>
                <tbody id="lugPhotoTBody">
                  <tr
                    v-for="(luggage, index) of luggageImagesList"
                    :key="index"
                  >
                    <td data-label="Luggage Type" scope="row">
                      {{ luggage.luggageDescription }}
                    </td>
                    <td data-label="Luggage Number">
                      {{ luggage.luggageNumber }}
                    </td>
                    <td data-label="Luggage xxx">
                      {{ luggage.luggageIsInsurance }}
                      <!-- {{
                        HandlingInstructions[parseInt(luggage.luggageXXX - 1)]
                          .handlingDescription
                      }} -->
                    </td>
                    <td>
                      {{ luggage.luggageWeight }}
                    </td>
                    <td data-label="Luggage Photo">
                      <!-- <div class="d-flex justify-content-around"> -->
                      <img
                        :class="displayCheck ? 'd-block ' : 'd-none'"
                        id="lgPhoto"
                        :src="luggage.luggageImageURL"
                        alt=""
                      />
                      <img
                        @click="
                          showImageThumbnail(
                            $event,
                            index,
                            luggage.luggageImageURL
                          )
                        "
                        id="eyeIcon"
                        :src="eyeIcon"
                        alt=""
                      />
                      <!-- </div> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eyeIcon from "../../assets/Icons/eye.svg";
import luggagePhoto from "../../assets/img/luggagePhoto.png";
import BookingService from "../../services/BookingService";
import LuggageService from "../../services/LuggageService";
import InvoiceBookingDetailsService from "../../services/InvoiceBookingDetailsService";
export default {
  name: "Luggages",
  data: function () {
    return {
      dashboardLabel: this.$store.getters.dashboardDetails,
      eyeIcon,
      luggagePhoto,
      luggageInsurance: "",
      displayCheck: false,
      bookingInvoiceSummary: {},
      HandlingInstructions: [],
      luggageImagesList: [],
      allData: "",
      myModel: false,
      actionButton: "Insert",
      dynamicTitle: "Add Data",
      uploadedLuggageImage: "",
      isLanguageEN: true,
    };
  },
  props: {
    luggages: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    const langType = localStorage.getItem("lang");
    if (langType === "EN") {
      this.isLanguageEN = true;
    } else if (langType === "AR") {
      this.isLanguageEN = false;
    }
  },
  methods: {
    closeModel() {
      this.myModel = false;
    },

    openModel: function (image) {
      this.actionButton = "Insert";
      this.dynamicTitle = "Add Data";
      this.myModel = true;

      this.uploadedLuggageImage = image;
    },

    showImageThumbnail(event, index, image) {
      this.openModel(image);

      window.scrollTo(0, 100);

      this.displayCheck = true;

      if (this.displayCheck) {
        this.displayCheck = false;
      } else {
        this.displayCheck = true;
      }
    },
  },
  created() {
    InvoiceBookingDetailsService.getInvoiceBookingDetails(
      this.$route.params.bookinID
    )
      .then((response) => {
        this.bookingInvoiceSummary = response.data;
        var data = response.data;
        var arr = [];
        // for (let i = 0; i < data.invoiceDetails.luggages.length; i++) {
        //   const luggageImageObg = {
        //     luggageDescription: data.invoiceDetails.luggages[i].luggageType,
        //     luggageNumber: data.invoiceDetails.luggages[i].bookingLuggageNumber,
        //     luggageImageURL: data.invoiceDetails.luggages[i].image,
        //     luggageXXX: "",
        //     luggageIsInsurance: "",
        //   };

        //   arr.push(luggageImageObg);
        // }

        for (let k = 0; k < data.invoiceDetails.luggages.length; k++) {
          for (
            let i = 0;
            i < data.invoiceDetails.luggages[k].luggageImages.length;
            i++
          ) {
            const luggageImageObg = {
              luggageDescription: data.invoiceDetails.luggages[k].luggageType,
              luggageNumber:
                data.invoiceDetails.luggages[k].luggageImages[i]
                  .bookingLuggageNumber,
              luggageImageURL:
                data.invoiceDetails.luggages[k].luggageImages[i].luggageImage,
              luggageWeight:
                data.invoiceDetails.luggages[k].luggageImages[i]
                  .luggageWeight == 0
                  ? "-"
                  : data.invoiceDetails.luggages[k].luggageImages[i]
                      .luggageWeight,
              // luggageXXX:
              //   data.invoiceDetails.luggages[k].luggageImages[i].luggageXXX,
              luggageIsInsurance: data.invoiceDetails.luggages[k].luggageImages[
                i
              ].bookingLuggageIsInsurance
                ? "Non Fragile"
                : "Fragile",
            };

            arr.push(luggageImageObg);
          }
        }
        this.luggageImagesList = arr;
      })
      .catch((error) => {});

    LuggageService.getHandlingInstructions().then((response) => {
      this.HandlingInstructions = response.data;
    });
  },
};
</script>

<style scoped>
.form-select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

#luggageLargePreview {
  position: relative;
  width: 100%;
  height: 300px;
  object-fit: contain;
  background-position: 50% 50%;
  background-size: cover;
}
#eyeIcon {
  cursor: pointer;
}
#luggageMainDiv {
  width: 585px;
}

.captionText {
  color: #006203;
}

#lgPhoto {
  width: 60px;
  height: 30px;
  object-fit: cover;
  background-position: 50% 50%;
  background-size: cover;
}

.luggagetDiv {
  width: 100%;
  border: 2px solid #e8e9eb;
  box-sizing: border-box;
  border-radius: 13px;
  background: #ffffff;
  padding-bottom: 40px;
}

table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table tr {
  background-color: #ffffff;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: left;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 1024px) {
  #luggageMainDiv {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  #luggageMainDiv {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  #luggageMainDiv {
    width: 100%;
  }
  #eyeIcon {
    margin-left: 148px;
  }
}

@media screen and (max-width: 600px) {
  #lgPhoto {
    margin-left: 6px;
  }

  table {
    border: 0;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
</style>
