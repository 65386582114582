<template>
  <div class="row col-lg-12 delDetailsDIv mx-0">
    <div class="col-lg-12">
      <div class="col-lg-11 dMapDiv mx-0">
        <div id="mapDiv" class="col-10 mapDiv" ref="mapDiv"></div>
      </div>
    </div>
    <div id="deliveryByDiv" class="row col-lg-12 mx-0 mb-3">
      <DeliverBy />
    </div>
  </div>
</template>

<script>
import SmallAvatar from "../../common/components/invoice/SmallAvatar";
import DeliverBy from "../../common/components/invoice/DeliverBy";
import BookingService from "../../services/BookingService";
import pickupLocationIcon from "../../assets/Icons/pickuplocation.png";
import haversine from "haversine-distance";
import InvoiceBookingDetailsService from "../../services/InvoiceBookingDetailsService";

export default {
  name: "deliveryDetails",
  components: { DeliverBy, SmallAvatar },
  data() {
    return {
      bookingDetailsByID: {},
      isLanguageEN: true,
    };
  },
  mounted() {
    const langType = localStorage.getItem("lang");
    if (langType === "EN") {
      this.isLanguageEN = true;
    } else if (langType === "AR") {
      this.isLanguageEN = false;
    }

    InvoiceBookingDetailsService.getInvoiceBookingDetails(
      this.$route.params.bookinID
    )
      .then((response) => {
        this.$store.dispatch("setSavedDetailsToStore", response.data);

        this.bookingDetailsByID = response.data;

        const locations = [
          {
            latitude: this.bookingDetailsByID.pickupLatitudes,
            longitude: this.bookingDetailsByID.pickupLongitudes,
            title: "Pickup location",
          },

          {
            latitude: this.bookingDetailsByID.dropoffLatitudes,
            longitude: this.bookingDetailsByID.dropoffLongitudes,
            title: "Drop off location",
          },
        ];

        const { latitude, longitude } = locations[0];

        const calculatedDistance = haversine(
          {
            lat: this.bookingDetailsByID.dropoffLatitudes,
            lng: this.bookingDetailsByID.dropoffLongitudes,
          },
          {
            lat: this.bookingDetailsByID.pickupLatitudes,
            lng: this.bookingDetailsByID.pickupLongitudes,
          }
        );
        var distance = (calculatedDistance / 1000).toFixed(0);

        const map = new google.maps.Map(document.getElementById("mapDiv"), {
          zoom: distance > 50 ? 7 : 9.7,
          center: new google.maps.LatLng(latitude, longitude),
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        });

        for (let i = 0; i < locations.length; i++) {
          if (i == 1) {
            const marker = new google.maps.Marker({
              position: new google.maps.LatLng(
                locations[i].latitude,
                locations[i].longitude
              ),
              map: map,
              title: locations[i].title,
              icon: {
                url: pickupLocationIcon,
                scaledSize: new google.maps.Size(40, 40),
              },
            });
          } else {
            const marker = new google.maps.Marker({
              position: new google.maps.LatLng(
                locations[i].latitude,
                locations[i].longitude
              ),
              map: map,
              title: locations[i].title,
            });
          }
        }

        (error) => {};
      })
      .catch((error) => {});
  },

  methods: {},
};
</script>

<style scoped>
.form-select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
}
.delDetailsDIv {
  background: #ffffff;
  border-radius: 13px;
  border: 2px solid #e8e9eb;
}

.dMapDiv {
  margin-top: 15px;
  width: 100%;
}
.mapDiv {
  width: 100%;
  border-radius: 15px;
  height: 300px;

  margin-top: 15px;
}

@media all and (max-width: 425px) {
  .delDetailsDIv {
    width: 100%;
    border-radius: 13px;
    margin-left: 1px;
  }
}

@media all and (max-width: 768px) {
  .delDetailsDIv {
    width: 100%;
    border-radius: 13px;
    margin-left: 1px;
  }
}
</style>
