<template>
  <div class="mt-1 smallAvatarCircle">
    <img :src="profileImage" alt="" class="smallAvatarImage" />
  </div>
</template>

<script>
import profileImage from "../../../assets/img/profileImage.png";

export default {
  name: "SmallAvatar",
  data: function () {
    return {
      profileImage,
    };
  },
};
</script>

<style scoped>
.smallAvatarCircle {
  width: 48px;
  height: 48px;
  border-radius: 100%;
}

.smallAvatarImage {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background-position: 50% 50%;
  background-size: cover;
}
</style>
