<template>
  <div class="col-12 pb-4">
    <div class="col-lg-12 col-12 bSummary" :dir="!isLanguageEN ? 'rtl' : ''">
      <div class="d-flex sumTitleDiv col-12 mt-3 mb-3 mx-0">
        <p class="me-3">{{ dashboardLabel.booking_Details }}</p>
        <div class="paymentSuccess">{{ dashboardLabel.cash }}</div>
      </div>
      <div class="row mt-3 mb-3 mx-0">
        <div class="sumDetails col-lg-6 col-sm-12">
          {{ dashboardLabel.passenger_Name }}
        </div>
        <div class="col-lg-6 col-sm-12">
          {{ primaryPassenger }}
        </div>
      </div>
      <div class="row mt-3 mb-3 mx-0">
        <div class="sumDetails col-lg-6 col-sm-12">
          {{ dashboardLabel.additional_Passengers }}
        </div>
        <div class="col-lg-6 col-sm-12">{{ passengerCount }}</div>
      </div>
      <div class="row mt-3 mb-3 mx-0">
        <div class="sumDetails col-lg-6 col-sm-12">
          {{ dashboardLabel.pickup_Location }}
        </div>
        <div class="col-lg-6 col-sm-12">
          {{ pickUpLocation }}
        </div>
      </div>
      <div class="row mt-3 mb-3 mx-0">
        <div class="sumDetails col-lg-6 col-sm-12">
          {{ dashboardLabel.drop_off_Location }}
        </div>
        <div class="col-lg-6 col-sm-12">
          {{ dropOffLocation }}
        </div>
      </div>
      <div class="row mt-3 mb-3 mx-0">
        <div class="sumDetails col-lg-6 col-sm-12">
          {{ dashboardLabel.pickup_Date_Time }}
        </div>
        <div class="col-lg-6 col-sm-12">
          {{ pickUpDatetTime }}
        </div>
      </div>
      <div class="row mt-3 mb-3 mx-0">
        <div class="sumDetails col-lg-6 col-sm-12">
          {{ dashboardLabel.Drop_off_Date_Time }}
        </div>
        <div class="col-lg-6 col-sm-12">
          {{ dropOffDateTime }}
        </div>
      </div>
      <div class="row mt-3 mb-3 mx-0">
        <div class="sumDetails col-lg-6 col-sm-12">
          {{ dashboardLabel.no_Of_Km }}
        </div>
        <div class="col-lg-6 col-sm-12">
          {{
            numberWithCommas(parseFloat(this.payment.totalDistance).toFixed(2))
          }}
          KM
        </div>
      </div>

      <div
        class="
          row
          d-flex
          justify-content-between
          align-items-center
          mt-3
          mb-3
          mx-0
        "
      >
        <div class="accordion-item px-1">
          <h2 id="LuggagesCollapsed" class="accordion-header">
            <button
              id="luggaggeFeeColButton"
              aria-controls="LuggagesCollapsedData"
              aria-expanded="false"
              class="ps-2 accordion-button collapsed"
              data-bs-target="#LuggagesCollapsedData"
              data-bs-toggle="collapse"
              type="button"
              @click="showLuggageDetails"
            >
              {{ dashboardLabel.luggages }}
            </button>
          </h2>
          <div
            id="LuggagesCollapsedData"
            aria-labelledby="headingThree"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div
              v-for="(lg, index) of luggageTypes"
              class="
                d-flex
                justify-content-between
                align-items-center
                accordion-body
                py-2
              "
              :key="index"
            >
              <p class="mb-0">{{ lg.luggageDescription }}</p>
              <p class="mb-0">{{ lg.luggageQty }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12 mt-sm-2 mx-0">
          {{ dashboardLabel.total_Luggages + " " + luggageCount }}
        </div>
      </div>
      <hr class="row mx-0" />
      <div
        class="
          row
          d-flex
          justify-content-between
          align-items-center
          mt-3
          mb-3
          mx-0
        "
      >
        <div class="accordion-item px-1">
          <h2 id="headingThree" class="accordion-header">
            <button
              id="collapseThreeBtn"
              aria-controls="collapseThree"
              aria-expanded="false"
              class="ps-2 accordion-button collapsed"
              data-bs-target="#collapseThree"
              data-bs-toggle="collapse"
              type="button"
              @click="showTotalFee"
            >
              {{ dashboardLabel.total_fee }}
            </button>
          </h2>
          <div
            id="collapseThree"
            aria-labelledby="headingThree"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                accordion-body
                py-2
              "
            >
              <p class="mb-0">
                {{ dashboardLabel.booking_Fee }} ({{ selectedCurrency }})
              </p>
              <p class="mb-0">
                {{ numberWithCommas(this.payment.bookingFee.toFixed(2)) }}
              </p>
            </div>

            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                accordion-body
                py-2
              "
            >
              <p class="mb-0">
                {{ dashboardLabel.luggage_Fee }} ({{ selectedCurrency }})
              </p>
              <p class="mb-0">
                {{ numberWithCommas(this.payment.lagguageFee.toFixed(2)) }}
              </p>
            </div>

            <!-- <div
              class="
                d-flex
                justify-content-between
                align-items-center
                accordion-body
                py-2
              "
            >
              <p class="mb-0">{{ dashboardLabel.sub_Total }}</p>
              <p class="mb-0">
                {{
                  numberWithCommas(parseFloat(this.payment.totalFee).toFixed(2))
                }}
              </p>
            </div> -->

            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                accordion-body
                py-2
              "
            >
              <p class="mb-0">
                {{ dashboardLabel.transportation_Fee }} ({{ selectedCurrency }})
              </p>
              <p class="mb-0">
                {{
                  numberWithCommas(
                    this.payment.transformationTotalFee.toFixed(2)
                  )
                }}
              </p>
            </div>

            <div v-if="isAdditionalServices">
              <hr />
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  accordion-body
                  py-2
                  ps-2
                "
                v-if="isAdditionalServices"
              >
                <p class="mb-0">Additional Services</p>
                <p class="mb-0">
                  {{
                    numberWithCommas(
                      this.payment.additionalServiceTotalCharges.toFixed(2)
                    )
                  }}
                </p>
              </div>

              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  accordion-body
                  py-2
                "
                v-for="(addtionalServicesRow, index) of this.payment
                  .additionalServiceCharges"
                :key="index"
              >
                <p class="mb-0 ml-2">
                  {{ addtionalServicesRow.serviceName }}
                </p>
                <p class="mb-0">
                  {{
                    numberWithCommas(
                      addtionalServicesRow.totalAmount.toFixed(2)
                    )
                  }}
                </p>
              </div>

              <!-- <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    accordion-body
                    py-2
                  "
                >
                  <p class="mb-0">Additional Service Fee</p>
                  <p class="mb-0">
                    {{
                      numberWithCommas(
                        this.payment.additionalServiceTotalCharges.toFixed(2)
                      )
                    }}
                  </p>
                </div> -->
              <hr />
            </div>
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                accordion-body
                py-2
                ps-2
              "
            >
              <p class="mb-0">
                {{ dashboardLabel.discount }} ({{ selectedCurrency }})
              </p>
              <p class="mb-0">
                {{
                  numberWithCommas(
                    parseFloat(this.payment.totalDiscountAmount).toFixed(2)
                  )
                }}
              </p>
            </div>
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                accordion-body
                py-2
                ps-2
              "
            >
              <p class="mb-0">{{ dashboardLabel.vat }} (15%)</p>
              <p class="mb-0">
                {{
                  numberWithCommas(parseFloat(this.payment.vatFee).toFixed(2))
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-sm-12 mt-sm-2 mx-0 text-end px-4">
          {{ selectedCurrency }}
          {{ numberWithCommas(parseFloat(this.payment.totalFee).toFixed(2)) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InvoiceBookingDetailsService from "../../services/InvoiceBookingDetailsService";
import moment from "moment";
import BookingService from "../../services/BookingService";

export default {
  name: "BookingDetails",
  data() {
    return {
      dashboardLabel: this.$store.getters.dashboardDetails,
      primaryPassenger: {},
      secondaryPassenger: this.$store.getters.getSecondaryPassengerDetails,
      dropOffLocation: {},
      // pickUpLocation: pickUpData,
      luggageDetails: this.$store.getters.getluggageInputDetails,
      luggageCount: 0,
      luggageTypes: [],
      passengerCount: 0,
      pickUpLocation: "",
      pickUpDatetTime: "",
      dropOffDateTime: "",
      bookingInvoiceSummary: {},
      luggageFeeDetails: 0,
      baseFee: 0,
      luggaggeFeeCol: false,
      totalFeeCol: false,
      isAdditionalServices: false,
      payment: {
        totalFee: 0,
        totalDistance: 0,
        totalDiscountAmount: 0,
        totalWithoutDiscountFee: 0,
        transformationFee: 0,
        transformationTotalFee: 0,
        totalAmount: 0,
        lagguageFee: 0,
        bookingFee: 0,
        vatFee: 0,
        additionalServiceCharges: [],
        additionalServiceTotalCharges: 0,
      },
      isLanguageEN: true,
      additionalServicesList: [],
      selectedCurrency: "SAR",
      currencyRate: 0,
    };
  },

  created() {
    InvoiceBookingDetailsService.getInvoiceBookingDetails(
      this.$route.params.bookinID
    )
      .then((response) => {
        var data = response.data;

        this.primaryPassenger = data.invoiceDetails.primaryPassenger;
        this.passengerCount = data.invoiceDetails.passengerCount;
        // this.primaryPassenger = response.data.passangerDetails[0];

        this.dropOffLocation = data.invoiceDetails.dropoffLocation;

        this.dropOffDateTime = data.invoiceDetails.dropoffLocation
          ? moment(data.invoiceDetails.dropoffDateTime).format("LLLL")
          : "-";

        this.pickUpDatetTime = data.invoiceDetails.pickupDateTime
          ? moment(data.invoiceDetails.pickupDateTime).format("LLLL")
          : "-";

        this.pickUpLocation = data.invoiceDetails.pickupLocation;

        let luggageTypeName = "";
        let luggageTotalCount = 0;

        for (let i = 0; i < data.invoiceDetails.luggages.length; i++) {
          if (luggageTypeName === data.invoiceDetails.luggages[i].luggageType) {
            continue;
          } else {
            luggageTypeName = data.invoiceDetails.luggages[i].luggageType;
            let luggageType = {
              luggageDescription: data.invoiceDetails.luggages[i].luggageType,
              luggageQty: data.invoiceDetails.luggages[i].bookingLuggageQty,
            };
            luggageTotalCount += parseInt(
              data.invoiceDetails.luggages[i].bookingLuggageQty
            );
            this.luggageTypes.push(luggageType);
          }
        }

        this.luggageCount = luggageTotalCount;
        const invoiceBreakdown = JSON.parse(
          data.invoiceDetails.invoiceBreakdown
        );

        this.isAdditionalServices =
          invoiceBreakdown.additionalServices.additionalServicesDetails.length >
          0
            ? true
            : false;
        // this.selectedCurrency = data.toCurrency;
        this.payment = {
          totalFee: data.invoiceDetails.netTotal * this.currencyRate,
          totalDistance: invoiceBreakdown.distanceBased.distance,
          totalDiscountAmount:
            data.invoiceDetails.discountedValue * this.currencyRate,
          totalWithoutDiscountFee:
            data.invoiceDetails.subTotal * this.currencyRate,
          transformationFee: 2.49 * this.currencyRate,
          transformationTotalFee:
            invoiceBreakdown.distanceBased.totalAmount * this.currencyRate,
          totalAmount: data.invoiceDetails.netTotal * this.currencyRate,
          additionalServiceCharges: invoiceBreakdown
            ? invoiceBreakdown.additionalServices.additionalServicesDetails
                .length > 0
              ? invoiceBreakdown.additionalServices.additionalServicesDetails.map(
                  (item, index) => ({
                    ...item,
                    totalAmount: item.totalAmount * this.currencyRate,
                  })
                )
              : []
            : [],
          additionalServiceTotalCharges:
            invoiceBreakdown.additionalServices.additionalServicesDetails
              .length > 0
              ? this.getAdditionalTotalFee(
                  invoiceBreakdown.additionalServices.additionalServicesDetails,
                  this.currencyRate
                )
              : 0,
          lagguageFee: this.getLagguageTotalFee(
            invoiceBreakdown.luggageBased,
            this.currencyRate
          ),
          bookingFee: 12.99 * this.currencyRate,
          vatFee:
            data.invoiceDetails.discountedValue == 0
              ? ((data.invoiceDetails.subTotal * this.currencyRate) / 100) * 15
              : ((data.invoiceDetails.discountedValue * this.currencyRate) /
                  100) *
                15,
        };
      })
      .catch((error) => {});

    // BookingService.getBookingDetailsByBookingId(this.$route.params.bookinID)
    //   .then((response) => {
    //     this.bookingInvoiceSummary = response.data;

    //     this.baseFee = this.numberWithCommas(
    //       this.bookingInvoiceSummary.vehicleDetails.vehicleBaseFee
    //     );

    //     response.data.luggageDetails.forEach((lg) => {
    //       this.luggageFeeDetails += lg.luggageFee;
    //     });

    //     response.data.luggageDetails.forEach((lg) => {
    //       this.luggageCount += parseInt(lg.luggageQty);
    //     });

    //     let luggageTypeName = "";
    //     let luggageTotalCount = 0;
    //     for (let i = 0; i < response.data.luggageDetails.length; i++) {
    //       if (
    //         luggageTypeName ===
    //         response.data.luggageDetails[i].luggageDescription
    //       ) {
    //         continue;
    //       } else {
    //         luggageTypeName =
    //           response.data.luggageDetails[i].luggageDescription;
    //         let luggageType = {
    //           luggageDescription:
    //             response.data.luggageDetails[i].luggageDescription,
    //           luggageQty: response.data.luggageDetails[i].luggageQty,
    //         };
    //         luggageTotalCount += parseInt(
    //           response.data.luggageDetails.luggageQty
    //         );
    //         this.luggageTypes.push(luggageType);
    //       }
    //     }

    //     this.payment = {
    //       totalFee:
    //         (response.data.totalFee / 100) * 15 + response.data.totalFee,
    //       totalDistance: response.data.totalDistance,
    //       totalDiscountAmount: response.data.totalDiscountAmount,
    //       totalWithoutDiscountFee: response.data.totalWithoutDiscountFee,
    //       transformationFee: response.data.transformationFee,
    //       transformationTotalFee: response.data.transformationTotalFee,
    //       totalAmount:
    //         response.data.totalFee + response.data.transformationTotalFee,
    //       lagguageFee: this.getLagguageTotalFee(response.data.luggageDetails),
    //       bookingFee: response.data.vehicleDetails.vehicleBaseFee,
    //       vatFee: (response.data.totalFee / 100) * 15,
    //     };

    //     // this.luggageCount = luggageTotalCount;
    //   })
    //   .catch((error) => {});
  },
  mounted() {
    const currentCurrencyRate = JSON.parse(localStorage.getItem("CR"));

    if (currentCurrencyRate != null) {
      this.selectedCurrency = currentCurrencyRate.currency;
      this.currencyRate = currentCurrencyRate.currentRate;
    }
    const langType = localStorage.getItem("lang");
    if (langType === "EN") {
      this.isLanguageEN = true;
    } else if (langType === "AR") {
      this.isLanguageEN = false;
    }
  },

  methods: {
    moment: moment,
    numberWithCommas(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    showLuggageDetails() {
      if (!this.luggaggeFeeCol) {
        this.luggaggeFeeCol = true;
        document.getElementById("LuggagesCollapsedData").classList.add("show");
      } else {
        document
          .getElementById("LuggagesCollapsedData")
          .classList.remove("collapse");

        setTimeout(() => {
          document
            .getElementById("LuggagesCollapsedData")
            .classList.remove("show");
          document
            .getElementById("luggaggeFeeColButton")
            .classList.add("collapsed");
        }, 600);

        this.luggaggeFeeCol = false;
      }
    },

    showTotalFee() {
      if (!this.totalFeeCol) {
        this.totalFeeCol = true;
        document.getElementById("collapseThree").classList.add("show");
      } else {
        document.getElementById("collapseThree").classList.remove("collapse");

        setTimeout(() => {
          document.getElementById("collapseThree").classList.remove("show");

          document
            .getElementById("collapseThreeBtn")
            .classList.add("collapsed");
        }, 600);

        this.totalFeeCol = false;
      }
    },

    getLagguageTotalFee(data, currencyRate) {
      let total = 0;

      data.map((item) => (total = total + item.value * currencyRate));

      return total;
    },
    getAdditionalTotalFee(data, currencyRate) {
      let total = 0;

      data.map((item) => (total = total + item.totalAmount * currencyRate));

      return total;
    },
  },
};
</script>

<style scoped>
.form-select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
}
.accordion-item {
  border: none;
  color: #3f434a;
}

.accordion-button:not(.collapsed) {
  color: #3f434a !important;
  background-color: #ffffff;
}

.accordion-button:focus {
  outline: none;
  box-shadow: none;
}

.accordion-item button {
  border-radius: 13px;
}

.bSummary {
  border: 2px solid #e8e9eb;
  box-sizing: border-box;
  border-radius: 13px;
  background: #ffffff;
  padding-left: 15px;
  width: 100%;
  margin-left: 0 !important;
}

.sumTitleDiv {
  color: #006203;
  font-weight: normal;
}

.sumDetails a {
  color: #006203;
}

.paymentSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 30px;
  background: #d1eed2;
  color: #006203;
  border-radius: 8px;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

@media all and (max-width: 425px) {
  .sumDetails:nth-child(1) {
    font-weight: bold;
  }

  .bSummary {
    width: 100%;
    margin-left: 25px;
  }
}

@media all and (max-width: 768px) {
  .sumDetails:nth-child(1) {
    font-weight: bold;
  }

  .bSummary {
    width: 100%;
    margin-left: 25px;
  }
}
</style>
